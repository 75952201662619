import React from 'react';
import { ContentCard, Button } from '@hpstellar/core';

import HyperLink from '../../../shared/components/hyperlink';
import { getContentCardContent } from '../../../utility/setHtml';
import { Helpers } from '../../../core';

import './css/blog-cell.less';

const BlogCell = props => {
    let { slug, blogDate, blogTitle, thumbnail: image, loading } = props;

    let d = new Date(blogDate);
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    let date = monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();

    if (typeof image === 'string') {
        image = {
            src: image,
            alt: 'HPImage',
            title: 'HPImage',
        };
    } else if (Array.isArray(image)) {
        image = {
            src: image[0],
            alt: 'HPImage',
            title: 'HPImage',
        };
    } else if (!image) {
        image = {
            src: null,
            alt: 'HPImage',
            title: 'HPImage',
        };
    }

    return (
        <div className="blog-cell">
            <HyperLink to={slug}>
                <ContentCard
                    type="vertical"
                    image={{
                        alt: image.alt,
                        url: {
                            desktop: Helpers.getCDNUrl(image.src, null, 640, null),
                        },
                        className: 'articleImage',
                        imageContainerClassName: 'articleImageContainer',
                        loading,
                    }}
                    {...getContentCardContent({ title: blogTitle, content: date })}
                    ctaComponent={
                        <Button color="dark" size="small">
                            Read More
                        </Button>
                    }
                />
            </HyperLink>
        </div>
    );
};

export default React.memo(BlogCell);
