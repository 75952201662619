import React from 'react';
import ImageBanner from '../../../shared/components/image-banner';

import HyperLink from '../../../shared/components/hyperlink';

import { getImageBannerContent } from '../../../utility/setHtml';

import './css/article-feature-cell.less';

const ArticleFeatureCell = props => {
    let { slug, featureDate, featureTitle, featureThumbnail: image } = props;

    let d = new Date(featureDate);
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    let date = monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();

    if (typeof image === 'string') {
        image = {
            src: image,
            alt: 'HPImage',
            title: 'HPImage',
        };
    } else if (Array.isArray(image)) {
        image = {
            src: image[0],
            alt: 'HPImage',
            title: 'HPImage',
        };
    } else if (!image) {
        image = {
            src: null,
            alt: 'HPImage',
            title: 'HPImage',
        };
    }

    return (
        <HyperLink class="hyperlink" to={slug}>
            <ImageBanner
                className="blogFeaturedBanner"
                image={{
                    alt: image.alt,
                    url: {
                        desktop: image.src,
                    },
                }}
                linkText="Read More"
                {...getImageBannerContent({ title: featureTitle, subtitle: date })}
                contentBackgroundTheme="transparent"
                contentAlignment="left"
                textTheme="dark"
            />
        </HyperLink>
    );
};

export default React.memo(ArticleFeatureCell);
