import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { url } from '@hp-us-store/hpstore-helpers';
import { Button, Link } from '@hpstellar/core';
import { Cross } from '@hpstellar/icons';

import { articleListPageType } from '../blog-list-actions';
import { withError } from '../../shared/components/error-boundary';
import JsonLd from '../../shared/components/json-ld';
import BlogCell from './blog-cell/blog-cell';
import ArticleFeatureCell from './blog-cell/article-feature-cell';
import { Helpers } from '../../core/src/helpers';
import FiltersContainer from '../../filters/components/filters-container';
import FilterMenu from '../../filters';
import SearchDrawer from '../../search-filter/components/search-drawer';
import Icon from '../../ui/components/icon';
import { ModalControl, ModalProvider } from '../../context/modal/modal-context';
import RightRail from './right-rail';
import BlogEmailForm from '../components/blog-email-form';
import { Grid, Column } from '../../ui/components/grid';
import PrivateStoreHeaderLoader from '../../page/components/header-footer/private-store-header-loader';
import MetricsMeta from '../../metrics/components';

import '../css/blog-list.less';
import '../css/blog-list-v2.less';
import '../css/blog-shared.less';
import { Typography } from '@hpstellar/core';

const initialState = {
    header: 'HP TECH TAKES /...',
    subHeader: "Exploring today's technology for tomorrow's possibilities",
    filters: {
        templateKey: 'blog',
        hosted: 'true',
        blogArchive: 'false',
    },
    aggregates: {
        all_years: 'year',
        all_months: 'month',
        all_categories: 'categories',
    },
    aggregateFilters: ['all_years', 'all_months', 'all_categories'],
    sort: {
        blogDate: 'desc',
    },
    size: 14,
    bucketSize: 30,
    filter: {
        pg: '',
    },
};

const searchFilters = {
    template_key: 'blog',
    hosted: 'true',
    blog_archive: 'false',
};

// pages that should be indexed, because all other filter blog listing pages
// will no longer be indexed
const seoIndexedFilters = {
    'tags=tech-at-work': 1,
    'categories=tech-at-work': 1,
};

function getBlogQueryObject(postFilters) {
    let postFiltersKey = postFilters && Object.keys(postFilters);
    return postFiltersKey.length > 0
        ? postFiltersKey.reduce((parsedFilters, nextFilter) => {
              parsedFilters['facets:' + nextFilter] = postFilters[nextFilter].join('+');
              return parsedFilters;
          }, {})
        : {};
}

class BlogList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blogs: [],
            blogHits: [],
            featuredArticle: this.props.featuredArticle || null,
            filtersResults: null,
            activeIndex: -1,
            postFilters: {},
            totalHits: 0,
            header: initialState.header,
            subHeader: initialState.subHeader,
            from: 0,
            selectedFilters: {},
        };

        this.filtersInitialSetup = this.filtersInitialSetup.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { url: prevUrl } = prevProps.match;
        const { url: currentUrl } = this.props.match;
        if (currentUrl !== prevUrl) {
            let postFilters = {};

            const { filters: filterParams } = this.props.match.params;

            if (filterParams) {
                const additionalFilters = Helpers.parseFilters(filterParams);
                postFilters = { ...additionalFilters.query };
            }
            let queryObject = getBlogQueryObject(postFilters);
            this.props.fetchBlogPages(
                initialState.filters,
                postFilters,
                initialState.aggregates,
                initialState.aggregateFilters,
                initialState.sort,
                initialState.size,
                0,
                queryObject,
                initialState.bucketSize,
            );
        }
        if (!this.props.brightEdgeLinks && this.props.fetchWordCloudData) {
            this.props.fetchWordCloudData();
        }
    }

    componentDidMount() {
        const { blogs, featuredArticle } = this.state;
        let postFilters = {};

        const { filters: filterParams } = this.props.match.params;

        let size = initialState.size;
        let sort = initialState.sort;

        //Check to see if there are filters parameters in the url
        if (filterParams) {
            const additionalFilters = Helpers.parseFilters(filterParams);

            const query = additionalFilters.query || {};
            if (query.size) {
                size = parseInt(query.size[0]);
                delete query.size;
            }
            if (query.sort) {
                sort = query.sort[0];
                delete query.sort;
            }
            postFilters = { ...query };
        }

        this.props.fetchBlogPages(
            initialState.filters,
            postFilters,
            initialState.aggregates,
            initialState.aggregateFilters,
            sort,
            size,
            0,
            {},
            initialState.bucketSize,
        );

        const query = {
            templateKey: 'blog',
            featured: 'true',
            limit: '1',
            hosted: 'true',
            sort: '-updatedAt',
        };
        let vanityUrl = 'tech-takes';
        this.props.fetchFeaturedArticle(query, { vanityUrl });
    }

    static constructSelectedFiltersObject(urlPath) {
        let { query: filters = {} } = url.parseFilters(urlPath);
        Object.keys(filters).forEach(filterKey => {
            if (filters[filterKey] instanceof Array) {
                filters[filterKey] = filters[filterKey].reduce((filterValues, nextFilterValue) => {
                    filterValues[nextFilterValue] = 1;
                    return filterValues;
                }, {});
            }
        });

        return filters;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (Object.keys(nextProps.blogHits).length) {
            //Featured article may change
            let { featuredArticle } = nextProps;
            let header = initialState.header;
            let subHeader = initialState.subHeader;

            const { aggregations, postFilters, query, from, hits: blogHits } = nextProps.blogHits;
            const { hits, total: totalHits } = blogHits;

            let blogs = hits.map(hit => {
                return hit['_source'];
            });

            //check to see if results should be appended
            if (from) {
                if (from !== prevState.from) {
                    const prevBlogs = [...prevState.blogs];
                    blogs = prevBlogs.concat(blogs);
                    //no need to update anything except blogs and from
                    return { blogs, from };
                }
                return null;
            }

            //If query is not empty, featured article will change
            //to  most recent article from that query
            if (postFilters && Object.keys(postFilters).length) {
                featuredArticle = blogs[0];
                blogs = blogs.splice(1);

                //check if header/subheader needs to change to tech@Work
                //condition only tech@work is selected under category
                if (
                    (postFilters.categories &&
                        postFilters.categories.length === 1 &&
                        postFilters.categories[0] === 'tech-at-work') ||
                    (postFilters.tags && postFilters.tags.length === 1 && postFilters.tags[0] === 'tech-at-work')
                ) {
                    header = 'HP Tech@Work';
                    subHeader = "Today's trends for tomorrow's business";
                }
            }

            const {
                all_years: allYears,
                all_months: allMonths,
                all_categories: allCategories,
            } = Object.keys(aggregations).reduce((result, key) => {
                result[key] = aggregations[key][`${key}_filtered`];
                return result;
            }, {});

            const { buckets: yearBuckets } = allYears;
            const { buckets: monthBuckets } = allMonths;
            let { buckets: categoryBuckets } = allCategories;

            //Sort years, months, and categories
            const monthsMap = {
                january: 0,
                february: 1,
                march: 2,
                april: 3,
                may: 4,
                june: 5,
                july: 6,
                august: 7,
                september: 8,
                october: 9,
                november: 10,
                december: 11,
            };

            yearBuckets.sort(function (a, b) {
                return a.key - b.key;
            });
            monthBuckets.sort(function (a, b) {
                if (monthsMap[a.key] < monthsMap[b.key]) {
                    return -1;
                }
                if (monthsMap[a.key] > monthsMap[b.key]) {
                    return 1;
                }
                return 0;
            });

            categoryBuckets.sort(function (a, b) {
                if (a.key.toLowerCase() < b.key.toLowerCase()) {
                    return -1;
                }
                if (a.key.toLowerCase() > b.key.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

            const categoriesMap = {
                accessories: 'Accessories',
                business: 'Business',
                components: 'Components',
                desktops: 'Desktops',
                'fa-qs': 'FAQs',
                faqs: 'FAQs',
                features: 'Features',
                gaming: 'Gaming',
                'how-tos': "How To's",
                infographics: 'Infographics',
                laptops: 'Laptops',
                lists: 'Lists',
                monitors: 'Monitors',
                printers: 'Printers',
                reviews: 'Reviews',
                security: 'Security',
                trends: 'Trends',
                'tech-takes': 'Tech Takes',
                'tech-at-work': 'Tech at Work',
            };

            let filtersResults = {
                facets: [
                    {
                        displayName: 'Year',
                        key: 'year',
                        _id: 'year',
                        aggregations: yearBuckets,
                        ...Helpers.getFiltersResults(yearBuckets),
                    },
                    {
                        displayName: 'Month',
                        key: 'month',
                        _id: 'month',
                        aggregations: monthBuckets,
                        ...Helpers.getFiltersResults(monthBuckets),
                    },
                    {
                        displayName: 'Topic',
                        key: 'categories',
                        _id: 'categories',
                        aggregations: categoryBuckets,
                        ...Helpers.getFiltersResults(categoryBuckets, categoriesMap),
                    },
                ],
            };

            if (prevState.filtersResults) {
                filtersResults = Helpers.updateFiltersResults(filtersResults, prevState.filtersResults);
                let queryObject = getBlogQueryObject(postFilters);
                filtersResults = Helpers.formatFiltersResults(filtersResults.facets, queryObject);
            }
            let selectedFilters = prevState.selectedFilters;

            // pressing back & forward button should also update the filters.
            let nextFilterPath = nextProps.match.params && nextProps.match.params.filters;
            if (!!nextFilterPath && nextFilterPath !== prevState.filterPath) {
                selectedFilters = BlogList.constructSelectedFiltersObject(nextFilterPath);
            }

            return {
                totalHits,
                featuredArticle,
                blogs,
                filtersResults,
                postFilters,
                header,
                subHeader,
                from,
                selectedFilters,
                filterPath: nextProps.match.params.filters,
            };
        }

        return null;
    }

    clearFilters() {
        const { match } = this.props;
        const { basename, dir } = match.params;
        setTimeout(() => {
            this.setState({ selectedFilters: {} }, () => {
                let queryString = this.props.location && this.props.location.search ? this.props.location.search : '';
                this.props.history.push(`/${basename}/${dir}${queryString}`);
            });
        }, 0);
    }

    constructFilterUrl = (query = {}) => {
        const { match } = this.props;
        const { basename, dir } = match.params;
        let newPath = url.getFilterString({ query });
        let queryString = this.props.location && this.props.location.search ? this.props.location.search : '';
        newPath =
            newPath.length > 0
                ? `/${basename}/${dir}/article-filters/${newPath}${queryString}`
                : `/${basename}/${dir}${queryString}`;

        return newPath;
    };

    navigateUrl = () => {
        let { selectedFilters } = this.state;
        let filterQueryObject = Object.keys(selectedFilters).reduce((allFilters, filterKey) => {
            allFilters[filterKey] = Object.keys(selectedFilters[filterKey]);
            return allFilters;
        }, {});

        let newPath = this.constructFilterUrl(filterQueryObject);
        this.props.history.replace(newPath);
    };

    loadMoreArticles = from => {
        const { postFilters } = this.state;
        this.props.fetchBlogPages(
            initialState.filters,
            postFilters,
            initialState.aggregates,
            initialState.aggregateFilters,
            initialState.sort,
            initialState.size,
            from,
            {},
            initialState.bucketSize,
        );
    };

    expandMenu = index => {
        const activeIndex = this.state.activeIndex === index ? -1 : index;
        this.setState({
            activeIndex,
        });
    };

    selectFilter = (key, value) => {
        this.setState(
            state => {
                let selectedFilters = Object.assign({}, state.selectedFilters);
                selectedFilters[key] = selectedFilters[key] || {};
                if (selectedFilters[key][value]) {
                    delete selectedFilters[key][value];
                    if (Object.keys(selectedFilters[key]).length === 0) delete selectedFilters[key];
                } else {
                    if (!selectedFilters[key]) selectedFilters[key] = {};
                    selectedFilters[key][value] = 1;
                }
                return { selectedFilters };
            },
            () => {
                this.navigateUrl();
            },
        );
    };

    selectFilterMobile = facet => {
        try {
            Object.keys(facet).forEach(facetKey => {
                let key = facetKey.replace(/^facets\./, '');
                let value = facet[facetKey];
                this.selectFilter(key, value);
            });
        } catch (e) {}
    };

    isFilterSelected = (facet, field) => {
        try {
            let { _id } = facet;
            return this.state.selectedFilters[field][_id];
        } catch (e) {}

        return false;
    };

    getBlogList = () => {
        const { featuredArticle, blogs, totalHits, postFilters } = this.state;
        let articleCount = 0;

        return blogs.map((blog, key) => {
            const {
                blogTitle: headline,
                canonical: url,
                blogDate: datePublished,
                authorName: name,
                vanityUrl,
                avatar,
                blogThumbnail,
            } = blog;
            const image = blogThumbnail ? blogThumbnail.src : '';

            const featuredArticleVanityUrl = featuredArticle ? featuredArticle.vanityUrl : '';
            // check if post filters is empty, if it's not subtract total hits by 1
            // One of the hits was used as the featured article
            const tHits = Object.keys(postFilters).length ? totalHits - 1 : totalHits;
            const allBlogsAreFetched = blogs.length === tHits;

            const isOdd =
                (key === blogs.length - 1 && articleCount % 2 === 0) ||
                (key === blogs.length - 2 && blogs[blogs.length - 1].vanityUrl === featuredArticleVanityUrl);
            //if featured or if the last blog would make the list uneven, expect for the very last article
            if (vanityUrl === featuredArticleVanityUrl || (!allBlogsAreFetched && isOdd)) {
                return null;
            } else {
                articleCount++;
                const data = {
                    headline,
                    image,
                    url,
                    datePublished,
                    dateModified: datePublished,
                    author: {
                        '@type': 'Person',
                        name: name || 'HP',
                    },
                };
                return (
                    <div className="blog-cell-wrapper" key={key}>
                        <JsonLd data={data} type="blog" />
                        <BlogCell
                            slug={vanityUrl}
                            author={{ authorName: name, avatar }}
                            blogDate={datePublished}
                            thumbnail={blogThumbnail}
                            blogTitle={headline}
                            loading={key < 3 ? 'eager' : null}
                        />
                    </div>
                );
            }
        });
    };

    processSearchBoxResponse = (response = {}) => {
        return Object.keys(response).length
            ? response.results.map(result => {
                  const { blog_title, vanity_url, blog_thumbnail, blog_date, blog_content, id, description } = result;

                  return {
                      blogTitle: blog_title.raw,
                      vanityUrl: vanity_url.raw,
                      blogThumbnail: JSON.parse(blog_thumbnail.raw),
                      blogDate: blog_date.raw,
                      blogContent: blog_content.raw,
                      description: description.raw,
                      document_id: id.raw,
                  };
              })
            : [];
    };

    filtersInitialSetup() {
        let { filters } = (this.props.match && this.props.match.params) || {};
        let initialFacets =
            filters && filters.length
                ? filters.split('&').reduce((allFilters, filter) => {
                      let filterSegment = filter.split('=');
                      if (filterSegment.length === 2) {
                          let [facetKey, facetValue] = filterSegment;
                          allFilters[`facets:${facetKey}`] = facetValue;
                          return allFilters;
                      }
                  }, {})
                : {};
        return initialFacets;
    }

    shouldNoIndex() {
        const { storeEnvironment, search } = this.props;
        const query = Helpers.getSearch(search);
        const filters = this.props && this.props.match && this.props.match.params && this.props.match.params.filters;
        return (filters && !seoIndexedFilters[filters]) || Helpers.shouldNotIndex(storeEnvironment, query);
    }

    toggleEmailSubscriptionModal = emailModalOpen => this.setState({ emailModalOpen });

    render() {
        const {
            featuredArticle,
            activeIndex,
            filtersResults,
            totalHits,
            postFilters,
            blogs,
            header,
            subHeader,
            emailModalOpen,
            selectedFilters,
            filterPath,
        } = this.state;
        const { title, description, canonical, blogConfig } = this.props;
        //if post filters exists, this means one of the articles
        //was used as the featured article, must take account by adding 1 to blog length
        const currentPage = Object.keys(postFilters).length ? blogs.length + 1 : blogs.length;
        const { categories, tags } = postFilters || {};
        const { hideSearchBox, hideBlogAd = true, subscribeCta } = blogConfig || {};
        const { links: subscribeLinks = {}, linkType: subscribeLinkType } = subscribeCta || {};
        const filterCount = postFilters && Object.entries(postFilters).reduce((acc, [k, v]) => acc + v.length, 0);

        let filtersMobile = (
            <div className="filters-bar-mobile">
                <SearchDrawer onClearFilter={this.clearFilters} resultCount={totalHits} label={'FILTER ARTICLES'}>
                    {props => {
                        let { closeDrawer } = props || {};
                        return (
                            <div className="filterContainer">
                                <div className={'header'}>
                                    <Icon name={'x'} onClick={closeDrawer} />
                                    <div className="headerTitle">
                                        {filterCount > 0 && `${filterCount} FILTERS APPLIED`}
                                    </div>
                                </div>
                                <FilterMenu
                                    className="v2"
                                    isV2={true}
                                    align={'right'}
                                    isFilterSelected={this.isFilterSelected}
                                    customFilterClick={this.selectFilterMobile}
                                    aggregationOverride={{ facets: filtersResults && filtersResults.facets }}
                                    onClearFilter={this.clearFilters}
                                    defaultExpandedFilters={0}
                                    checkboxAligned="right"
                                />
                            </div>
                        );
                    }}
                </SearchDrawer>
            </div>
        );
        return (
            <div className={`blog-list v2`}>
                {/*TODO: PrivateStoreHeaderLoader is being rendered here since the blog components don't use the withPage HOC.*/}
                <PrivateStoreHeaderLoader />
                <MetricsMeta />
                <Grid className="blog-grid">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                        <link rel="canonical" href={canonical} />
                        {this.shouldNoIndex() && <meta name="robots" content="noindex" />}
                    </Helmet>
                    <Column
                        columnClass="allow-overflow blog-header-column"
                        xl={12}
                        large={12}
                        medium={12}
                        small={8}
                        xs={4}
                        xxs={4}
                    >
                        <div className="hp-tech-takes-header">
                            <div className="hp-tech-takes-title">
                                <Typography
                                    variant="display"
                                    tag="h4"
                                    className={`tech-takes-header${header === initialState.header ? ' hp-blue' : ''}`}
                                >
                                    {header}
                                </Typography>
                                <Typography className="tech-takes-subheader" tag="div">
                                    {subHeader}
                                </Typography>
                            </div>
                            {emailModalOpen && (
                                <BlogEmailForm
                                    isOpen={emailModalOpen}
                                    handleClose={() => this.toggleEmailSubscriptionModal(false)}
                                />
                            )}
                            <div>
                                <div className="filters-bar-desktop">
                                    <div>
                                        <FiltersContainer
                                            isV2={true}
                                            expandMenu={this.expandMenu}
                                            activeIndex={activeIndex}
                                            orientation="horizontal"
                                            key="filter-group"
                                            customFacetUpdate={this.selectFilter}
                                            selectedFacets={this.state.selectedFilters}
                                            filtersResults={filtersResults || {}}
                                            filters={initialState.filter}
                                            updateUrl={false}
                                        />
                                        {selectedFilters.month || selectedFilters.year || selectedFilters.categories ? (
                                            <Button
                                                className="clearFilterBtn"
                                                variation="secondary"
                                                size="small"
                                                endIcon={<Cross size="xs" />}
                                                onClick={this.clearFilters}
                                            >
                                                Clear Filters
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Column>
                    <Column columnClass="blog-content-column" xl={9} large={9} medium={8} small={8} xs={4} xxs={4}>
                        <ModalProvider className="blog-container">
                            {!featuredArticle ? (
                                <div></div>
                            ) : (
                                <div className="featured-article-wrapper">
                                    <JsonLd
                                        data={{
                                            headline: featuredArticle.blogTitle,
                                            datePublished: featuredArticle.blogDate,
                                            dateModified: featuredArticle.blogDate,
                                            url: featuredArticle.canonical,
                                            author: { '@type': 'Person', name: featuredArticle.authorName || 'HP' },
                                            image: featuredArticle.blogThumbnail
                                                ? featuredArticle.blogThumbnail.src
                                                : '',
                                        }}
                                        type="blog"
                                    />

                                    <ArticleFeatureCell
                                        featureTitle={featuredArticle.blogTitle}
                                        featureDate={featuredArticle.blogDate}
                                        featureThumbnail={featuredArticle.blogThumbnail}
                                        slug={featuredArticle.vanityUrl}
                                    />
                                </div>
                            )}
                            {filtersMobile}
                            <div className="article-list-and-trending">
                                <div className="article-list">{this.getBlogList()}</div>
                                <Link
                                    className={`paginationLink${totalHits <= currentPage ? ' hide' : ''}`}
                                    size="large"
                                    onClick={() => this.loadMoreArticles(currentPage)}
                                    to={null}
                                >
                                    Show more articles
                                </Link>
                                <Button
                                    variation="secondary"
                                    color="primary"
                                    size="large"
                                    onClick={() => this.loadMoreArticles(currentPage)}
                                    className={`paginationButton${totalHits <= currentPage ? ' hide' : ''}`}
                                >
                                    Show more articles
                                </Button>
                            </div>
                        </ModalProvider>
                    </Column>
                    <Column columnClass="blog-right-rail-column" xl={3} large={3} medium={4} small={8} xs={4} xxs={4}>
                        <RightRail
                            bannerType="article-list"
                            hideSearchBox={hideSearchBox}
                            hideBlogAd={hideBlogAd}
                            bannerMeta={{ categories, tags, bannerKey: filterPath }}
                            categories={categories}
                            tags={tags}
                            tagsHeader="Popular tags"
                            page={articleListPageType}
                            match={this.props.match}
                            subscribeLinkType={subscribeLinkType}
                            subscribeLinkId="blog-list-view:subscribe"
                            subscribeHref={subscribeLinks.BLOGLIST}
                        />
                    </Column>
                </Grid>
            </div>
        );
    }
}

export default withError(BlogList);
